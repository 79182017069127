import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { SellersComponent } from './pages/sellers/sellers.component';
import { BuyersComponent } from './pages/buyers/buyers.component';
import { ExpertiseComponent } from './pages/expertise/expertise.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ImageButtonComponent } from './shared/image-button/image-button.component';
import { TestimonialCardComponent } from './shared/testimonial-card/testimonial-card.component';
import { MessageBubbleComponent } from './shared/message-bubble/message-bubble.component';
import { ContactFormComponent } from './shared/contact-form/contact-form.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    SellersComponent,
    BuyersComponent,
    ExpertiseComponent,
    ServicesComponent,
    ContactUsComponent,
    ImageButtonComponent,
    TestimonialCardComponent,
    MessageBubbleComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
