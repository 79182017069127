<footer class="pageFooter position-relative d-flex">
    <div class="alignCenter w-100 align-items-center d-flex">
        <div class="container pt-14 pb-7 pb-lg-9">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <!-- txtWrap of the page -->
                    <div class="txtWrap pt-1 pr-lg-5 pr-xl-10 mb-6">
                        <div class="fLogo mb-6 mb-lg-9">
                            <a href="home.html">
                                <img src="../../../assets/images/logo2.png" alt="Montero" class="img-fluid">
                            </a>
                        </div>
                        <p>2021 © <a href="home.html">Freedom of Movement Realty.</a> All rights reserved.</p>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <h6 class="h5 text-capitalize mb-5 mb-sm-7 mb-lg-9">Services</h6>
                    <!-- fLink of the page -->
                    <ul class="fLink list-unstyled mb-6">
                        <li><a routerLink="/buyers">Buyers</a></li>
                        <li><a routerLink="/sellers">Sellers</a></li>
                        <li><a routerLink="/expertise">Expertise</a></li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <h6 class="h5 text-capitalize mb-5 mb-sm-7 mb-lg-9">Learn More</h6>
                    <!-- fLink of the page -->
                    <ul class="fLink list-unstyled mb-6">
                        <li><a routerLink="about">About Us</a></li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <h6 class="h5 text-capitalize mb-5 mb-sm-7 mb-lg-9">social media</h6>
                    <!-- socialNetworks of the page -->
                    <ul class="socialNetworks d-flex flex-wrap list-unstyled">
                        <li><a  class="fab fa-facebook-f"></a></li>
                        <li><a  class="fab fa-twitter"></a></li>
                        <li><a  class="fab fa-instagram"></a></li>
                        <li><a  class="fab fa-vimeo-v"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    <span class="circleGradient d-sm-block overflow-hidden rounded-circle position-absolute" data-circle="circleI"></span>
    <span class="circleGradient d-sm-block overflow-hidden rounded-circle position-absolute" data-circle="circleII"></span>
    <span class="ovelGradient d-block position-absolute">
        <img src="../../assets/images/ovel2.png" class="img-fluid" alt="Graident image">
    </span>
</div></footer>