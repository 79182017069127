import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BuyersComponent } from './pages/buyers/buyers.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ExpertiseComponent } from './pages/expertise/expertise.component';
import { HomeComponent } from './pages/home/home.component';
import { SellersComponent } from './pages/sellers/sellers.component';
import { ServicesComponent } from './pages/services/services.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: 'home', pathMatch: 'full', component: HomeComponent },
  { path: 'services', pathMatch: 'full', component: ServicesComponent },
  { path: 'sellers', pathMatch: 'full', component: SellersComponent },
  { path: 'buyers', pathMatch: 'full', component: BuyersComponent },
  { path: 'expertise', pathMatch: 'full', component: ExpertiseComponent },
  { path: 'about', pathMatch: 'full', component: AboutUsComponent },
  { path: 'contact-us', pathMatch: 'full', component: ContactUsComponent },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
