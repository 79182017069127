import { Injectable } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { init } from 'emailjs-com';

@Injectable({
  providedIn: 'root',
})
export class EmailerService {
  constructor() {
    init('user_u1CJTUhzYnMlS5twYzqUI');
  }

  sendEmail(name, emailAddress, phoneNumber, message) {
    emailjs.send('service_ew00cfu', 'template_a2jsieg', {
      from_name: name,
      to_name: 'fomrealty@gmail.com',
      message: "Message from email: " + emailAddress + " \n" + message,
    });
  }
}
