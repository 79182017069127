<section class="contactBlock d-flex position-relative text-center">
  <svg
    style="position: absolute; height: 96%; z-index: -1; bottom: 0px"
    xmlns="http://www.w3.org/2000/svg"
    width="1897.232"
    height="1075.641"
    viewBox="0 0 1897.232 1075.641"
  >
    <g
      id="Group_214"
      data-name="Group 214"
      transform="translate(-71.244 -4244)"
    >
      <g
        id="Group_142"
        data-name="Group 142"
        transform="translate(18552.602 -432.7) rotate(90)"
      >
        <line
          id="Line_7"
          data-name="Line 7"
          y2="28.659"
          transform="translate(4696.028 18447.699)"
          fill="none"
          stroke="#ff6584"
          stroke-linecap="round"
          stroke-width="10"
        />
        <line
          id="Line_8"
          data-name="Line 8"
          x2="28.659"
          transform="translate(4681.699 18462.029)"
          fill="none"
          stroke="#ff6584"
          stroke-linecap="round"
          stroke-width="10"
        />
      </g>
      <g
        id="Ellipse_203"
        data-name="Ellipse 203"
        transform="translate(1747.773 5267.548)"
        fill="none"
        stroke="#ff6584"
        stroke-width="12"
      >
        <ellipse
          cx="26.046"
          cy="26.046"
          rx="26.046"
          ry="26.046"
          stroke="none"
        />
        <ellipse cx="26.046" cy="26.046" rx="20.046" ry="20.046" fill="none" />
      </g>
      <g
        id="Group_140"
        data-name="Group 140"
        transform="matrix(0.839, -0.545, 0.545, 0.839, 773.078, 4735.659)"
      >
        <path
          id="Path_753"
          data-name="Path 753"
          d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0"
          transform="translate(-4872.965 -18163.082)"
          fill="none"
          stroke="#685ff6"
          stroke-linecap="round"
          stroke-width="15"
        />
        <path
          id="Path_754"
          data-name="Path 754"
          d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0"
          transform="translate(-4819.841 -18163.082)"
          fill="none"
          stroke="#685ff6"
          stroke-linecap="round"
          stroke-width="15"
        />
      </g>
      <g
        id="Group_141"
        data-name="Group 141"
        transform="matrix(0.839, -0.545, 0.545, 0.839, 842.078, 4722.659)"
      >
        <path
          id="Path_753-2"
          data-name="Path 753"
          d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0"
          transform="translate(-4872.965 -18163.082)"
          fill="none"
          stroke="#685ff6"
          stroke-linecap="round"
          stroke-width="5"
        />
        <path
          id="Path_754-2"
          data-name="Path 754"
          d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0"
          transform="translate(-4819.841 -18163.082)"
          fill="none"
          stroke="#685ff6"
          stroke-linecap="round"
          stroke-width="5"
        />
      </g>
    </g>
  </svg>

  <div class="alignCenter w-100 d-flex align-items-center p-2">
    <div class="container">
      <div class="row justify-content-center">
        <h2 class="mb-4 mb-xl-6">
          <strong class="h4 mb-2 d-block text-primary">Contact Us</strong>
        </h2>
        <header class="header text-center text-md-center mb-6 mb-lg-9">
          <p>
            Please feel free to contact us for more information about selling
            your property or starting the search for your dream property. Our
            expert real estate agents will be happy to guide you every step of
            the way.
          </p>
        </header>
      </div>

      <!-- NOT SUBMITTED -->
      <div
        *ngIf="!submitted"
        class="row flex-row-reverse"
        style="
          align-items: center;
          background-color: #ecf2fd;
          border-radius: 15px;
        "
      >
        <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12" style="padding: 30px">
          <form
            class="text-primary"
            style="font-weight: bold"
            #contactForm="ngForm"
          >
            <div class="row">
              <div
                class="
                  form-group
                  col-sm-12 col-md-12 col-xl-12 col-lg-12
                  text-md-left
                "
              >
                <label for="name">Name*</label>
                <input
                  required
                  name="name"
                  [(ngModel)]="name"
                  type="text"
                  #inputName="ngModel"
                  [ngClass]="['form-control', inputName.valid || inputName.pristine ?'':  'is-invalid' ]"
                />
                <small
                  [hidden]="inputName.valid || inputName.pristine"
                  class="form-text text-danger"
                >
                    First name is required
                </small>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  form-group
                  col-sm-12 col-md-6 col-xl-6 col-lg-6
                  text-md-left
                "
              >
                <label for="emailAddress">Email Address*</label>
                <input
                  required
                  name="emailAddress"
                  type="email"
                  pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                  [(ngModel)]="emailAddress"
                  #inputEmailAddress="ngModel"
                  [ngClass]="['form-control', inputEmailAddress.valid || inputEmailAddress.pristine ?'':  'is-invalid' ]"
                />
                <small
                  [hidden]="inputEmailAddress.valid || inputEmailAddress.pristine"
                  class="form-text text-danger"
                >
                    Invalid Email
                </small>
              </div>
              <div
                class="
                  form-group
                  col-sm-12 col-md-6 col-xl-6 col-lg-6
                  text-md-left
                "
              >
                <label for="phoneNumber">Phone Number</label>
                <input
                  name="phoneNumber"
                  [(ngModel)]="phoneNumber"
                  type="number"
                  minlength="10"
                  maxlength="10"
                  pattern="\d{10}"
                  #inputPhoneNumber="ngModel"
                  [ngClass]="['form-control', inputPhoneNumber.valid || inputPhoneNumber.pristine ?'':  'is-invalid' ]"
                />
                <small
                  [hidden]="inputPhoneNumber.valid || inputPhoneNumber.pristine"
                  class="form-text text-danger"
                >
                    Invalid phone number
                </small>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 text-md-left">
                <label for="textarea-message">Message</label>
                <textarea
                  name="message"
                  [(ngModel)]="message"
                  id="textarea-message"
                  rows="5"
                  #inputMessage="ngModel"
                  [ngClass]="['form-control', inputMessage.valid || inputMessage.pristine ?'':  'is-invalid' ]"
                ></textarea>
                <small
                  [hidden]="inputMessage.valid || inputMessage.pristine"
                  class="form-text text-danger"
                >
                    Invalid message
                </small>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 text-md-right">
                <button
                  [disabled]="
                    !contactForm.form.valid ||
                    !inputEmailAddress.valid ||
                    !inputName.valid ||
                    !inputLName.valid ||
                    !inputMessage.valid ||
                    !inputPhoneNumber.valid
                  "
                  (click)="onSubmit()"
                  class="btn btn-primary"
                >
                  <i class="fas fa-paper-plane"></i> Contact us
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          class="col-xl-3 col-lg-3 col-md-4 col-sm-12"
          style="
            background-color: #6c63ff;
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            height: 500px;
            position: relative;
          "
        >
          <svg
            style="
              position: absolute;
              width: 160px;
              height: 160px;
              right: -50px;
              bottom: -50px;
              z-index: 0;
            "
            xmlns="http://www.w3.org/2000/svg"
            width="409"
            height="451"
            viewBox="0 0 409 451"
          >
            <g
              id="Group_216"
              data-name="Group 216"
              transform="translate(-14430 -13408)"
            >
              <circle
                id="Ellipse_353"
                data-name="Ellipse 353"
                cx="195"
                cy="195"
                r="195"
                transform="translate(14449 13469)"
                fill="#ff6584"
              />
              <circle
                id="Ellipse_354"
                data-name="Ellipse 354"
                cx="107"
                cy="107"
                r="107"
                transform="translate(14430 13408)"
                fill="#ecf2fd"
              />
            </g>
          </svg>

          <div style="align-items: start; vertical-align: start; z-index: 2">
            <h3 class="mb-4 mb-xl-6 text-md-left">
              <strong class="h4 mb-2 d-block text-light">Get in touch</strong>
            </h3>
            <p class="text-md-left text-light">
              Fill out the form and our team will get in touch with you.
            </p>
            <ul
              class="
                fLink
                list-unstyled
                mb-6
                text-light text-md-left
                ml-5
                mt-15
              "
            >
              <li>
                <a href="Tel:650-963-1616" class="fas fa-phone text-light"></a
                ><strong> (650) 963-1616</strong>
              </li>
              <li>
                <a
                  href="mailto:fomrealty@gmail.com"
                  class="fas fa-envelope text-light"
                ></a
                ><strong> fomrealty@gmail.com</strong>
              </li>
              <li>
                <a
                  class="fas fa-map-pin text-light"
                ></a
                ><strong> 2005 De La Cruz Blvd., Suite 185
                  Santa Clara, CA 95050</strong>
              </li>
              <li>
                <a
                  href="https://twitter.com/fomrealty"
                  class="fab fa-twitter text-light"
                ></a
                ><strong> @fomrealty</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- HAS BEEN SUBMITTED -->
      <div
        *ngIf="submitted"
        class="row flex-row-reverse"
        style="
          align-items: center;
          background-color: #ecf2fd;
          border-radius: 15px;
          height: 600px;
        "
      >
        <div class="col-12">
          <img src="../../../assets//images/svg/message-sent.svg" class="submitted-illust"  alt="">
          <h2 class="mb-4 mb-xl-6">
            <strong class="h4 mb-2 d-block text-primary">Our Team Will Contact You Soon!</strong>
          </h2>
        </div>
      </div>
    </div>
  </div>
</section>