<main>
    <!-- introBlock of the page -->
    <section class="introBlock position-relative d-flex" data-scroll-index="0">
        <div class="alignCenter w-100 d-flex align-items-center">
            <div class="container pt-25 pt-sm-30 pt-md-36 pt-xl-40 pb-10">
                <div class="row flex-row-reverse">
                    <div class="col-12 col-lg-6" style="display: flex;justify-content: center;margin: auto;">
                        <!-- caption of the page -->
                        <div class="caption pr-lg-4 pr-xl-12 mb-6">
                            <!-- <a href="tel:0123456789" class="btn btn-sm btn-outline-primary text-uppercase mb-4 fwSemibold">Call us: <span>0123456789</span></a> -->
                            <h1 style="color:#3F3D56; font-size: 4rem; font-weight: bold;" class="text-capitalize mb-4 mb-lg-5">Contact Us</h1>
                            <p style="color:#3F3D56; font-size: 1.2rem; font-weight: lighter;">Get in touch with one of our experts</p>
                            <div class="btnHolder ">
                                <a (click)="goToBottom()"  class="btn btn-primary text-uppercase rounded-pill fwSemibold" [routerLink]="['/contact-us']">
                                    contact us <span class="fas fa-arrow-right ml-1 ml-sm-2"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <span class="imgHolder mx-auto d-block pb-20">
                            <svg xmlns="http://www.w3.org/2000/svg" class="img-fluid" width="797.544" height="726.624" viewBox="0 0 797.544 726.624">
                                <g id="Group_292" data-name="Group 292" transform="translate(-140 -187.314)">
                                  <g id="icon9" transform="translate(-10 -117.965)">
                                    <g id="Group_89" data-name="Group 89" transform="matrix(0.839, -0.545, 0.545, 0.839, -247.854, 1127.195)">
                                      <path id="Path_753" data-name="Path 753" d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0" transform="translate(-4872.965 -18163.082)" fill="none" stroke="#5d55dc" stroke-linecap="round" stroke-width="15"/>
                                      <path id="Path_754" data-name="Path 754" d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0" transform="translate(-4819.841 -18163.082)" fill="none" stroke="#5d55dc" stroke-linecap="round" stroke-width="15"/>
                                    </g>
                                    <g id="Group_90" data-name="Group 90" transform="matrix(0.839, -0.545, 0.545, 0.839, -178.854, 1114.195)">
                                      <path id="Path_753-2" data-name="Path 753" d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0" transform="translate(-4872.965 -18163.082)" fill="none" stroke="#5d55dc" stroke-linecap="round" stroke-width="5"/>
                                      <path id="Path_754-2" data-name="Path 754" d="M5849.765,18549.861s11.5,12.715,26.489,0,26.636,0,26.636,0" transform="translate(-4819.841 -18163.082)" fill="none" stroke="#5d55dc" stroke-linecap="round" stroke-width="5"/>
                                    </g>
                                  </g>
                                  <g id="contact-us" transform="translate(140 314.996)">
                                    <path id="Path_2031" data-name="Path 2031" d="M828.549,210.725c4.653-18.979.229-41.112-14.8-53.6a90.031,90.031,0,0,1-22.02,57.757c-5.393,6.171-12.034,12.472-12.3,20.663-.163,5.1,2.325,9.992,5.754,13.767s7.754,6.6,12.027,9.381l.628,1.107C811.595,245.929,823.9,229.7,828.549,210.725Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2032" data-name="Path 2032" d="M813.443,157.407a76.952,76.952,0,0,1,2.45,47.282,33.137,33.137,0,0,1-4.048,9.561,19.006,19.006,0,0,1-7.557,6.554c-2.825,1.428-5.879,2.563-8.308,4.653a11.637,11.637,0,0,0-3.915,8.675c-.117,4.087,1.241,7.972,2.7,11.725,1.621,4.167,3.371,8.47,3,13.032-.045.553.815.591.86.039.652-7.938-4.739-14.643-5.594-22.342-.4-3.593.282-7.327,2.939-9.947,2.323-2.291,5.472-3.463,8.344-4.884a20.281,20.281,0,0,0,7.628-6.133,30.635,30.635,0,0,0,4.4-9.37,74.655,74.655,0,0,0,2.985-23.142,78.316,78.316,0,0,0-5.121-26.1c-.2-.516-.958-.112-.763.4Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2033" data-name="Path 2033" d="M817.533,199.737a11.545,11.545,0,0,0,12.52-8.171c.156-.532-.663-.8-.819-.266a10.691,10.691,0,0,1-11.661,7.577C817.023,198.8,816.987,199.665,817.533,199.737Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2034" data-name="Path 2034" d="M805.816,220.269a22.253,22.253,0,0,1-4.721-15.515c.043-.553-.817-.591-.86-.039a23.146,23.146,0,0,0,4.945,16.135c.345.435.979-.148.636-.581Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2035" data-name="Path 2035" d="M818.083,174.658a6.535,6.535,0,0,1-5.675-2.5c-.34-.439-.973.145-.636.581a7.323,7.323,0,0,0,6.272,2.779.445.445,0,0,0,.45-.411.433.433,0,0,0-.411-.45Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2036" data-name="Path 2036" d="M748.735,169.416c.2.28.409.56.611.847a86.066,86.066,0,0,1,7.223,11.948c.166.32.329.647.486.971A90.732,90.732,0,0,1,765.86,215.4a88.112,88.112,0,0,1,.062,13.388c-.441,6.156-1.408,12.8.164,18.568a15.114,15.114,0,0,0,.583,1.778l29.769,11.347c.081-.008.159-.009.24-.017l1.168.507c.032-.229.061-.467.094-.7.019-.133.032-.268.052-.4.011-.089.021-.179.036-.258,0-.03.007-.059.008-.082.015-.08.02-.156.032-.229q.247-1.994.467-4c0-.007,0-.007,0-.016a129.556,129.556,0,0,0,.007-30.63c-.04-.3-.077-.613-.131-.921a84.073,84.073,0,0,0-2.977-13.394,74.339,74.339,0,0,0-2.609-7.054A61.811,61.811,0,0,0,782.5,186.96c-8.528-9.707-20.066-16.579-32.789-17.485C749.382,169.453,749.063,169.432,748.735,169.416Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2037" data-name="Path 2037" d="M748.658,169.819A76.953,76.953,0,0,1,779.082,206.1a33.139,33.139,0,0,1,2.525,10.071,19.007,19.007,0,0,1-2.088,9.783c-1.4,2.841-3.151,5.586-3.832,8.717a11.638,11.638,0,0,0,2.1,9.284c2.367,3.334,5.79,5.618,9.216,7.736,3.8,2.351,7.791,4.733,10.239,8.6.3.469,1.007-.019.71-.487-4.259-6.73-12.6-8.838-17.918-14.471-2.482-2.628-4.186-6.02-3.642-9.711.476-3.227,2.284-6.059,3.722-8.924a20.283,20.283,0,0,0,2.4-9.489,30.636,30.636,0,0,0-2.128-10.131A74.656,74.656,0,0,0,768.831,186.8a78.319,78.319,0,0,0-19.8-17.759c-.468-.294-.833.487-.368.779Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2038" data-name="Path 2038" d="M777.409,201.155a11.545,11.545,0,0,0,5.077-14.062c-.2-.518-1.011-.238-.815.281a10.691,10.691,0,0,1-4.749,13.071c-.482.273.008.982.487.71Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2039" data-name="Path 2039" d="M780.415,224.6a22.253,22.253,0,0,1-13.11-9.545c-.3-.468-1.008.02-.71.487a23.147,23.147,0,0,0,13.663,9.905c.538.139.693-.708.158-.847Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2040" data-name="Path 2040" d="M762.749,180.8a6.535,6.535,0,0,1-6.036,1.421c-.535-.146-.69.7-.158.847a7.323,7.323,0,0,0,6.681-1.557.445.445,0,0,0,.112-.6.433.433,0,0,0-.6-.112Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2041" data-name="Path 2041" d="M709.374,598.115c-99.8,0-181-81.2-181-181s81.2-181,181-181a182.794,182.794,0,0,1,31.5,2.731,1,1,0,1,1-.368,1.966,180.78,180.78,0,0,0-31.132-2.7c-98.7,0-179,80.3-179,179s80.3,179,179,179,179-80.3,179-179a178.548,178.548,0,0,0-46.7-120.569,1,1,0,1,1,1.478-1.348,180.544,180.544,0,0,1,47.217,121.916C890.374,516.919,809.178,598.115,709.374,598.115Z" transform="translate(-235.874 -150.529)" fill="#e4e4e4"/>
                                    <circle id="Ellipse_486" data-name="Ellipse 486" cx="35.811" cy="35.811" r="35.811" transform="translate(517.496 76.041)" fill="#6c63ff"/>
                                    <path id="Path_2042" data-name="Path 2042" d="M784.3,281.019a3.982,3.982,0,0,1-3.187-1.594L771.34,266.4a3.983,3.983,0,0,1,6.374-4.78l6.392,8.522,16.416-24.624a3.984,3.984,0,1,1,6.629,4.419l-19.54,29.309a3.985,3.985,0,0,1-3.2,1.773Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2043" data-name="Path 2043" d="M704.717,389.026,649.494,235.4l1.4-.5-10.156-28.257-1.4.5L622.382,159.97a14.256,14.256,0,0,0-18.237-8.593l-99.832,35.885a14.256,14.256,0,0,0-8.594,18.237l11.822,32.889-2.213.852,7.547,19.6,1.771-.682,1.567,4.359-1.885.726,7.547,19.6,1.444-.556,54.736,152.274a14.23,14.23,0,0,0,18.238,8.594l99.832-35.885a14.273,14.273,0,0,0,8.593-18.239Z" transform="translate(-235.874 -150.529)" fill="#3f3d56"/>
                                    <path id="Path_2044" data-name="Path 2044" d="M591.041,438.086a6.845,6.845,0,0,1-6.424-4.524l-83.2-231.475a6.831,6.831,0,0,1,4.118-8.739l21.241-7.635-.032.743a7.874,7.874,0,0,0,10.53,7.742l47.141-16.944a7.974,7.974,0,0,0,4.488-5.636,7.808,7.808,0,0,0-1.861-6.835l-.5-.553,21.941-7.887a6.84,6.84,0,0,1,8.739,4.118L696.6,381.306a18.147,18.147,0,0,1-10.927,23.188L593.356,437.68a6.842,6.842,0,0,1-2.315.406Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <circle id="Ellipse_487" data-name="Ellipse 487" cx="27" cy="27" r="27" transform="translate(320.033 69.377)" fill="#6c63ff"/>
                                    <path id="Path_2045" data-name="Path 2045" d="M649.813,286.17l-93.5,33.61a2.362,2.362,0,1,1-1.6-4.445l93.5-33.61a2.362,2.362,0,0,1,1.6,4.445Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2046" data-name="Path 2046" d="M622.046,307.48l-30.755,11.055a2.362,2.362,0,0,1-1.6-4.445l30.755-11.055a2.362,2.362,0,1,1,1.6,4.445Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2047" data-name="Path 2047" d="M629.361,327.831l-30.755,11.055a2.362,2.362,0,1,1-1.6-4.445l30.755-11.055a2.362,2.362,0,0,1,1.6,4.445Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2048" data-name="Path 2048" d="M633.108,338.255,602.352,349.31a2.362,2.362,0,1,1-1.6-4.445L631.51,333.81a2.362,2.362,0,0,1,1.6,4.445Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2049" data-name="Path 2049" d="M642.9,311.313l-65.253,23.456a2.362,2.362,0,1,1-1.6-4.445L641.3,306.868a2.362,2.362,0,1,1,1.6,4.445Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2050" data-name="Path 2050" d="M629.758,363.558l-11.167,4.014a4.016,4.016,0,1,1-2.717-7.559L627.041,356a4.016,4.016,0,0,1,2.717,7.559Z" transform="translate(-235.874 -150.529)" fill="#6c63ff"/>
                                    <circle id="Ellipse_488" data-name="Ellipse 488" cx="1.553" cy="1.553" r="1.553" transform="translate(304.329 32.827)" fill="#fff"/>
                                    <path id="Path_2051" data-name="Path 2051" d="M363.157,272.252c17.406-20.488,45.647-33.4,71.684-26.706a123.863,123.863,0,0,0-46.634,71.113c-2.531,10.987-4.2,23.471-13.122,30.366-5.55,4.291-13.014,5.464-19.986,4.685s-13.608-3.323-20.155-5.845l-1.741.193C337.581,319.535,345.752,292.741,363.157,272.252Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2052" data-name="Path 2052" d="M434.775,246.107A105.87,105.87,0,0,0,380.089,281.5a45.591,45.591,0,0,0-7.4,12.221,26.148,26.148,0,0,0-1.214,13.708c.685,4.3,1.883,8.62,1.511,13.013a16.011,16.011,0,0,1-6.514,11.358c-4.461,3.426-9.887,5.044-15.248,6.442-5.953,1.552-12.16,3.07-16.944,7.167-.58.5-1.316-.432-.737-.928,8.323-7.126,20.145-6.522,29.417-11.776,4.327-2.452,7.941-6.222,8.719-11.3.681-4.436-.551-8.892-1.282-13.24a27.9,27.9,0,0,1,.687-13.448,42.149,42.149,0,0,1,6.9-12.46,102.713,102.713,0,0,1,23.391-21.986,107.747,107.747,0,0,1,33.229-15.338c.735-.2.9.978.168,1.173Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2053" data-name="Path 2053" d="M384.289,275.674a15.884,15.884,0,0,1-.985-20.544c.467-.6,1.425.1.957.7a14.708,14.708,0,0,0,.955,19.109c.524.554-.407,1.288-.928.737Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2054" data-name="Path 2054" d="M370.846,305.29a30.615,30.615,0,0,0,21.1-7.246c.581-.494,1.318.434.737.928a31.844,31.844,0,0,1-21.974,7.5c-.763-.034-.624-1.211.135-1.177Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2055" data-name="Path 2055" d="M411.8,254.842a8.991,8.991,0,0,0,7.362,4.311c.763.025.623,1.2-.135,1.177a10.075,10.075,0,0,1-8.155-4.751.612.612,0,0,1,.1-.832A.6.6,0,0,1,411.8,254.842Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2056" data-name="Path 2056" d="M473.557,327.924c-.477,0-.954,0-1.437,0a118.4,118.4,0,0,0-19.142,1.58c-.49.073-.986.154-1.474.241a124.826,124.826,0,0,0-43.022,16.163,121.224,121.224,0,0,0-14.975,10.724c-6.508,5.454-13.13,11.886-20.832,14.787a20.8,20.8,0,0,1-2.452.784l-36.651-24.038c-.056-.1-.118-.185-.174-.281l-1.507-.893c.229-.221.471-.445.7-.665.132-.129.273-.252.4-.38.091-.084.182-.168.259-.249.03-.028.061-.056.085-.076.077-.081.157-.148.229-.221q2.024-1.883,4.082-3.745c.005-.008.005-.008.019-.011,10.478-9.428,21.834-18.065,34.14-24.7.37-.2.746-.408,1.133-.6a115.675,115.675,0,0,1,17.332-7.48,102.263,102.263,0,0,1,9.967-2.779,85.038,85.038,0,0,1,26.536-1.658c17.7,1.681,34.66,9,45.927,22.457C472.994,327.233,473.274,327.572,473.557,327.924Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2057" data-name="Path 2057" d="M473.169,328.336a105.87,105.87,0,0,0-64.97-4.669,45.59,45.59,0,0,0-13.263,5.305,26.148,26.148,0,0,0-9.222,10.215c-2.042,3.846-3.686,8.017-6.628,11.3a16.011,16.011,0,0,1-12.04,5.147c-5.624.049-10.931-1.926-16.053-4.037-5.687-2.344-11.558-4.87-17.844-4.479-.762.047-.79-1.137-.03-1.184,10.936-.679,20.011,6.921,30.578,8.308,4.931.647,10.086-.187,13.762-3.769,3.214-3.132,4.913-7.431,6.948-11.344a27.9,27.9,0,0,1,8.645-10.324,42.149,42.149,0,0,1,13.01-5.8,102.713,102.713,0,0,1,31.914-3.471,107.747,107.747,0,0,1,35.765,7.761c.7.285.128,1.321-.572,1.038Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2058" data-name="Path 2058" d="M415.057,321.547a15.884,15.884,0,0,1,11.583-17c.736-.2,1.081.934.344,1.134a14.708,14.708,0,0,0-10.742,15.832c.084.758-1.1.783-1.184.03Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2059" data-name="Path 2059" d="M386.493,337.1a30.615,30.615,0,0,0,21.211,6.92c.762-.045.791,1.14.03,1.184a31.844,31.844,0,0,1-22.057-7.245c-.589-.487.231-1.343.816-.859Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2060" data-name="Path 2060" d="M449.567,321.479a8.991,8.991,0,0,0,3.282,7.875c.594.479-.226,1.335-.816.859a10.075,10.075,0,0,1-3.651-8.7.612.612,0,0,1,.577-.607.6.6,0,0,1,.607.577Z" transform="translate(-235.874 -150.529)" fill="#f0f0f0"/>
                                    <path id="Path_2061" data-name="Path 2061" d="M885.524,666.043c18.979,4.653,41.112.229,53.6-14.8a90.032,90.032,0,0,1-57.757-22.02c-6.171-5.393-12.472-12.034-20.663-12.3-5.1-.163-9.992,2.325-13.767,5.754s-6.6,7.754-9.381,12.027l-1.107.628C850.32,649.088,866.545,661.389,885.524,666.043Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2062" data-name="Path 2062" d="M938.842,650.936a76.953,76.953,0,0,1-47.282,2.45A33.14,33.14,0,0,1,882,649.338a19.007,19.007,0,0,1-6.554-7.557c-1.429-2.825-2.563-5.879-4.653-8.308a11.637,11.637,0,0,0-8.675-3.915c-4.087-.117-7.972,1.241-11.725,2.7-4.167,1.621-8.47,3.371-13.032,3-.553-.045-.591.815-.039.86,7.938.652,14.643-4.739,22.342-5.594,3.593-.4,7.327.282,9.947,2.939,2.291,2.323,3.463,5.472,4.884,8.344a20.28,20.28,0,0,0,6.133,7.628,30.637,30.637,0,0,0,9.371,4.4,74.658,74.658,0,0,0,23.142,2.986,78.317,78.317,0,0,0,26.1-5.121c.516-.2.112-.958-.4-.763Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2063" data-name="Path 2063" d="M896.512,655.026a11.545,11.545,0,0,0,8.171,12.52c.532.156.8-.663.266-.819a10.691,10.691,0,0,1-7.577-11.661C897.445,654.516,896.584,654.48,896.512,655.026Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2064" data-name="Path 2064" d="M875.98,643.309a22.253,22.253,0,0,1,15.515-4.721c.553.043.591-.817.039-.86a23.146,23.146,0,0,0-16.135,4.945C874.964,643.018,875.548,643.652,875.98,643.309Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2065" data-name="Path 2065" d="M921.591,655.577a6.535,6.535,0,0,1,2.5-5.675c.439-.34-.145-.973-.581-.636a7.323,7.323,0,0,0-2.779,6.272.445.445,0,0,0,.411.45.433.433,0,0,0,.45-.411Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2066" data-name="Path 2066" d="M926.833,586.228c-.28.2-.56.409-.847.611a86.055,86.055,0,0,1-11.947,7.223c-.32.166-.647.329-.971.486a90.732,90.732,0,0,1-32.223,8.805,88.113,88.113,0,0,1-13.388.062c-6.156-.441-12.8-1.408-18.568.164a15.114,15.114,0,0,0-1.778.583l-11.347,29.769c.008.081.009.159.017.24l-.507,1.168c.229.032.467.061.7.094.133.019.268.032.4.052.089.011.179.021.258.036.03,0,.06.007.082.008.08.015.156.02.229.032q1.994.247,4,.467c.007,0,.007,0,.016,0a129.563,129.563,0,0,0,30.63.007c.3-.04.613-.077.921-.131a84.079,84.079,0,0,0,13.394-2.977,74.306,74.306,0,0,0,7.054-2.609,61.812,61.812,0,0,0,16.335-10.326c9.707-8.528,16.579-20.066,17.484-32.789C926.8,586.876,926.817,586.556,926.833,586.228Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2067" data-name="Path 2067" d="M926.43,586.151a76.952,76.952,0,0,1-36.277,30.424,33.142,33.142,0,0,1-10.071,2.525,19.006,19.006,0,0,1-9.783-2.088c-2.841-1.4-5.586-3.152-8.717-3.832a11.638,11.638,0,0,0-9.284,2.1c-3.334,2.367-5.618,5.79-7.735,9.216-2.351,3.8-4.733,7.791-8.6,10.239-.469.3.019,1.007.487.71,6.73-4.259,8.838-12.6,14.471-17.918,2.628-2.482,6.02-4.186,9.711-3.642,3.227.476,6.059,2.284,8.924,3.722a20.282,20.282,0,0,0,9.489,2.4,30.635,30.635,0,0,0,10.131-2.128,74.659,74.659,0,0,0,20.275-11.549,78.315,78.315,0,0,0,17.758-19.8c.294-.468-.487-.833-.779-.368Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2068" data-name="Path 2068" d="M895.094,614.9a11.545,11.545,0,0,0,14.062,5.077c.518-.2.238-1.011-.281-.815a10.691,10.691,0,0,1-13.071-4.749c-.273-.482-.982.007-.71.487Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2069" data-name="Path 2069" d="M871.646,617.909a22.253,22.253,0,0,1,9.545-13.11c.468-.3-.02-1.008-.487-.71a23.146,23.146,0,0,0-9.905,13.663c-.139.538.708.693.847.158Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <path id="Path_2070" data-name="Path 2070" d="M915.449,600.243a6.535,6.535,0,0,1-1.421-6.036c.146-.535-.7-.69-.847-.158a7.324,7.324,0,0,0,1.557,6.681.445.445,0,0,0,.6.112A.433.433,0,0,0,915.449,600.243Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2071" data-name="Path 2071" d="M961.571,483.63l-28.94,48.7,1.1.58-9.8,18.58-2.04-1.08-6.44,10.83,1.6.92-10.5,18.18-1.82-1.05L810.3,738.21a18.274,18.274,0,0,1-25.02,6.37L609.731,640.27a18.272,18.272,0,0,1-6.37-25.02l151.27-254.58a18.274,18.274,0,0,1,25.02-6.37L955.2,458.61A18.281,18.281,0,0,1,961.571,483.63Z" transform="translate(-235.874 -150.529)" fill="#3f3d56"/>
                                    <path id="Path_2072" data-name="Path 2072" d="M791.537,734.753,615.369,630.076a5.634,5.634,0,0,1-1.963-7.712L765.679,366.09a5.634,5.634,0,0,1,7.712-1.963L949.56,468.8a5.634,5.634,0,0,1,1.963,7.712L799.25,732.79a5.634,5.634,0,0,1-7.713,1.963Z" transform="translate(-235.874 -150.529)" fill="#fff"/>
                                    <circle id="Ellipse_489" data-name="Ellipse 489" cx="1.553" cy="1.553" r="1.553" transform="translate(626.475 258.78)" fill="#fff"/>
                                    <path id="Path_2073" data-name="Path 2073" d="M854.291,595.994,707.234,508.615a4.066,4.066,0,1,1,4.154-6.991L858.445,589a4.066,4.066,0,1,1-4.154,6.991Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2074" data-name="Path 2074" d="M795.572,582.454,747.2,553.713a4.066,4.066,0,0,1,4.154-6.991l48.371,28.741a4.066,4.066,0,1,1-4.154,6.991Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2075" data-name="Path 2075" d="M776.554,614.461,728.183,585.72a4.066,4.066,0,1,1,4.154-6.991l48.371,28.741a4.066,4.066,0,1,1-4.154,6.991Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2076" data-name="Path 2076" d="M766.813,630.855l-48.371-28.741a4.066,4.066,0,1,1,4.154-6.991l48.371,28.741a4.066,4.066,0,1,1-4.154,6.991Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2077" data-name="Path 2077" d="M813.325,614.352,710.7,553.373a4.066,4.066,0,0,1,4.154-6.991l102.627,60.979a4.066,4.066,0,1,1-4.154,6.991Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2078" data-name="Path 2078" d="M793.764,692.284,776.2,681.848a6.914,6.914,0,0,1,7.064-11.888L800.828,680.4a6.914,6.914,0,0,1-7.064,11.888Z" transform="translate(-235.874 -150.529)" fill="#6c63ff"/>
                                    <circle id="Ellipse_490" data-name="Ellipse 490" cx="36" cy="36" r="36" transform="translate(554.659 300.314)" fill="#6c63ff"/>
                                    <path id="Path_2079" data-name="Path 2079" d="M236.3,633.134H723.479V351.086a7.98,7.98,0,0,0-7.971-7.971H244.269a7.98,7.98,0,0,0-7.971,7.971Z" transform="translate(-235.874 -150.529)" fill="#cacaca"/>
                                    <rect id="Rectangle_595" data-name="Rectangle 595" width="457.077" height="259.491" transform="translate(15.476 203.611)" fill="#fff"/>
                                    <path id="Path_2080" data-name="Path 2080" d="M235.874,625.5v34.8a10.987,10.987,0,0,0,10.987,10.987H426.676v68.689h-2.389a1.427,1.427,0,0,0-1.427,1.427v4.778a1.427,1.427,0,0,0,1.427,1.427H535.914a1.427,1.427,0,0,0,1.427-1.427V741.41a1.427,1.427,0,0,0-1.427-1.427h-2.389V671.295H713.34a10.987,10.987,0,0,0,10.987-10.987V625.5Z" transform="translate(-235.874 -150.529)" fill="#3f3d56"/>
                                    <path id="Path_2081" data-name="Path 2081" d="M642.287,440.923H425.833a5.145,5.145,0,0,1,0-10.29H642.287a5.145,5.145,0,1,1,0,10.29Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2082" data-name="Path 2082" d="M569.659,464.149h-71.2a5.145,5.145,0,0,1,0-10.291h71.2a5.145,5.145,0,1,1,0,10.29Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2083" data-name="Path 2083" d="M569.659,511.26h-71.2a5.145,5.145,0,1,1,0-10.29h71.2a5.145,5.145,0,0,1,0,10.291Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2084" data-name="Path 2084" d="M569.659,535.39h-71.2a5.145,5.145,0,0,1,0-10.29h71.2a5.145,5.145,0,1,1,0,10.29Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2085" data-name="Path 2085" d="M609.589,487.374H458.531a5.145,5.145,0,1,1,0-10.29H609.589a5.145,5.145,0,1,1,0,10.29Z" transform="translate(-235.874 -150.529)" fill="#e6e6e6"/>
                                    <path id="Path_2086" data-name="Path 2086" d="M638.684,584.795H612.832a8.749,8.749,0,0,1,0-17.5h25.852a8.749,8.749,0,0,1,0,17.5Z" transform="translate(-235.874 -150.529)" fill="#6c63ff"/>
                                    <circle id="Ellipse_491" data-name="Ellipse 491" cx="45" cy="45" r="45" transform="translate(76.895 280.766)" fill="#6c63ff"/>
                                    <path id="Path_2087" data-name="Path 2087" d="M884.359,749.471H237.065a1.191,1.191,0,1,1,0-2.381H884.359a1.191,1.191,0,0,1,0,2.381Z" transform="translate(-235.874 -150.529)" fill="#cacaca"/>
                                    <circle id="Ellipse_492" data-name="Ellipse 492" cx="1.696" cy="1.696" r="1.696" transform="translate(242.106 194.707)" fill="#fff"/>
                                  </g>
                                  <circle id="Ellipse_493" data-name="Ellipse 493" cx="10" cy="10" r="10" transform="matrix(0.999, 0.035, -0.035, 0.999, 254.355, 187.314)" fill="#94b6f0"/>
                                  <circle id="Ellipse_494" data-name="Ellipse 494" cx="10" cy="10" r="10" transform="matrix(0.999, 0.035, -0.035, 0.999, 533.355, 270.657)" fill="#3f3d56"/>
                                  <circle id="Ellipse_495" data-name="Ellipse 495" cx="4" cy="4" r="4" transform="matrix(0.999, 0.035, -0.035, 0.999, 181.142, 394.206)" fill="#6c63ff"/>
                                  <circle id="Ellipse_496" data-name="Ellipse 496" cx="3.554" cy="3.554" r="3.554" transform="matrix(0.999, 0.035, -0.035, 0.999, 872.395, 562.667)" fill="#ff6584"/>
                                </g>
                              </svg>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <svg style="position: absolute;z-index:-1; top:-150px" xmlns="http://www.w3.org/2000/svg" width="1924.73" height="1080.943" viewBox="0 0 1924.73 1080.943">
            <path id="Path_752" data-name="Path 752" d="M6957.654,18180v356.777s46.335,408.449-655.87,399.865c-168.578-1.738-209.919,105-327.925,166.537-89.519,46.68-254.694,43.641-370.847,84.127-184.278,64.238-569.459,73.637-569.459,73.637V18180Z" transform="translate(-5033.554 -18180)" fill="#ecf2fd"/>
        </svg>
        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute" style="width: 200px; transform:scale(-1,1); bottom: 0px; right: -100px">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute" style="width: 150px; left: -80px; top: 200px">
                    <img src="../../assets/images/icon8.png" alt="icon" class="img-fluid">
                </span>

                <span class="position-absolute icnImg" data-icon="icon3">
                    <img src="../../assets/images/icon10.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute" style="left: 50%; top: 220px; width: 40px">
                    <img src="../../assets/images/icon11.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>
    <br><br>
    <br>
    <app-contact-form></app-contact-form>
    <br><br>
</main>