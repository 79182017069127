import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EmailerService } from '../services/emailer.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  name: string = null;
  emailAddress: string = null;
  phoneNumber: string = null;
  message: string = null;

  submitted:boolean = false;

  constructor(private emailer: EmailerService) {}

  ngOnInit() {}

  onSubmit() {
    this.emailer.sendEmail(
      this.name,
      this.emailAddress,
      this.phoneNumber,
      this.message
    );

    this.name = null;
    this.emailAddress = null;
    this.phoneNumber = null;
    this.message = null;

    this.submitted = true;
  }

  isValid() {
    if (this.name == null) return false;
    if (this.emailAddress == null) return false;
    if (this.phoneNumber == null) return false;
    if (this.message == null) return false;

    if (this.name.length < 1) return false;
    if (this.emailAddress.length < 1) return false;
    if (this.phoneNumber.length != 10) return false;
    if (this.message.length < 1) return false;
  }
}
