<main>
    <!-- introBlock of the page -->
    <section class="introBlock position-relative d-flex" data-scroll-index="0">
        <div class="alignCenter w-100 d-flex align-items-center">
            <div class="container pt-25 pt-sm-30 pt-md-36 pt-xl-40 pb-10">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <span  class="imgHolder mx-auto d-block pt-md-3">
                            <img src="../../assets/images/svg/about-us-illust.svg" alt="image description" class="img-fluid">
                        </span>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- caption of the page -->
                        <div class="caption pr-lg-4 pr-xl-12 mb-6">
                            <!-- <a href="tel:0123456789" class="btn btn-sm btn-outline-primary text-uppercase mb-4 fwSemibold">Call us: <span>0123456789</span></a> -->
                            <h1 style="color:#3F3D56; font-size: 4rem; font-weight: bold;" class="text-capitalize mb-4 mb-lg-5">About us</h1>
                            <p style="color:#3F3D56; font-size: 1.2rem; font-weight: lighter;">Freedom of Movement Realty combines over 20 years of real estate experience to provide clients with the absolute best in real estate services.</p>
                            <div class="btnHolder ">
                                <a  class="btn btn-primary text-uppercase rounded-pill fwSemibold" [routerLink]="['/contact-us']">
                                    contact us <span class="fas fa-arrow-right ml-1 ml-sm-2"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <svg style="position: absolute;z-index:-1" xmlns="http://www.w3.org/2000/svg" width="1924.604" height="1080.943" viewBox="0 0 1924.604 1080.943">
            <path id="Path_760" data-name="Path 760" d="M5033.554,18180s-50.541,755.121,861.724,741.922,1062.377,339.021,1062.377,339.021V18180Z" transform="translate(-5033.051 -18180)" fill="#ecf2fd"/>
            <rect width="100%" height="100%" style="clip-path: url(#pt02);" fill="#4a83e5" fill-opacity="0.00"></rect>
          </svg>
          
        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute" style="right: -50px; bottom: 50px; width: 150px; " data-icon="icon1">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <!-- <span  class="position-absolute icnImg" data-icon="icon2">
                    <img src="../../assets/images/icon2.png" alt="icon" class="img-fluid">
                </span> -->
                <span class="position-absolute icnImg" data-icon="icon3">
                    <img src="../../assets/images/icon3.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon="icon4">
                    <img src="../../assets/images/icon4.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon="icon5">
                    <img src="../../assets/images/icon5.png" alt="icon" class="img-fluid">
                </span>
                <span  class="position-absolute icnImg" style="width: 150px; z-index: 100; bottom: 140px;" data-icon="icon6">
                    <img src="../../assets/images/icon6.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>

    <!-- WORD FROM PRES -->
    <section  class="serviceBlock position-relative py-10 pb-md-8 pt-lg-15 pt-xl-21 pb-lg-11" data-scroll-index="1">
        <!-- Word from president -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">A Word From The President</strong>	
                </h2>
            </div>
            <div class="row flex-row-reverse"  style="align-items: center; justify-content: center;">
                
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">At Freedom of Movement Realty, we foster an atmosphere of collaboration and community, where management is approachable and available, and actively participates in day-to-day operations. Our staff, management, and agents work hard and thrive on seeing everyone succeed. The expectation of excellence runs through every level of the company and we support each other in achieving our collective goal. Our agents are armed with the information they need  — we routinely host free training and seminars on topics ranging from technology and marketing to the latest on compliance and legal issues. And beyond business, we believe it is important to create a community among our agents, both in-office and as a company. Our monthly picnics gather all of our agents in one place so they can get to know each other better, share best practices, and just have fun.

                            </p>
                        <p class="text-secondary">
                            - Siraj Shabber | President, Freedom of Movement Realty</p>
                        </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0" style="width: 75%;">
                        <img src="/../../assets/images/svg/president.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <!--Experience & Experts -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">Excellence, Experience, Experts</strong>	
                </h2>
            </div>
            <div class="row "  style="align-items: center; justify-content: space-between;">
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">Freedom of Movement Realty combines over 20 years of real estate experience to provide clients with the absolute best in real estate services.</p>
                        <p class="text-secondary">We started this business with a vision that no one should be restricted to a certain location or house because of limitations of resources, budget or simply because they cannot find their ideal property. During all of our years of service, we have worked hard to ensure that every client, buyer or seller, experiences  our  principle philosophy — we are all free to move to the place where we want to live.
                        </p>
                    </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/experience-excellence-experts.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <!-- Experience and Experts 2 -->
        <div class="container">
            <div class="row flex-row-reverse"  style="align-items: center; justify-content: space-between;">
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">As we’ve grown, many like-minded people with exceptional qualifications, experience, and expertise have joined us in the journey. Our team is complete, with specialized departments that ensure our process is hassle-free and our services are top-notch. Moreover, Freedom of Movement continuously evolves to keep up with the changing markets and trends. Our system is infused with the latest technology available to deliver  the most advanced realty process that scientific progress allows.  
                            In addition to being the home of some of the finest real estate agents in the business, and crowned with closing deals on some of the most notable properties around, Freedom of Movement Realty is blessed with a rich client base and a vast network of mortgage brokers, escrow officers and other agents.  
                            </p>
                    </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/experience-excellence-experts-2.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>

        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute" style="left: -10px; top: 40%; width: 150px; transform: scale(-1,1);" data-icon="icon1">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>

    <!-- OUR EXPERTISE SECTION -->
    <section style="background-color: #ecf2fd;" class="serviceBlock position-relative py-10 pb-md-8 pt-lg-15 pt-xl-21 pb-lg-11" data-scroll-index="1">
        <svg style="position: absolute; z-index:-1; top:-100px" xmlns="http://www.w3.org/2000/svg" width="1920.002" height="1092.232" viewBox="0 0 1920.002 1092.232">
            <path id="Union_2" data-name="Union 2" d="M-17102.994-8494.9h-.008v-31.931c1157.352,89.976,1920-84.167,1920-84.167v686.852s2.55,123.82-345.718,173.907c-156.261,22.474-323.557,10.855-494.974-.764-210.636-14.276-427.5-28.552-637.778,20.427-381.412,88.833-438.123,211.812-438.123,211.812Z" transform="translate(17103.002 8610.998)" fill="#ecf2fd"/>
        </svg>
        
        <!-- WHO ARE WE SECTION -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h3 mb-2 d-block text-primary">Our Expertise</strong>	
                </h2>
                <header class="header text-center text-md-center mb-6 mb-lg-9">
                    <p class="text-secondary">Freedom of Movement Realty is known in the market for providing high-value services. This is one of the primary reasons we have established the position that we enjoy today.</p>
                </header>
            </div>
            <div class="row flex-row-reverse"  style="align-items: center;">
                <div class="col-12 col-md-6 p-5" style="display: flex; justify-content: center;">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/crm-software.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h2 class="mb-4 mb-xl-6">
                        <strong class="h4 mb-2 d-block text-primary">State of the line CRM Software</strong>	
                    </h2>
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">
                            To start with, all of our data is managed using our CRM system, which allows us to keep a sharp eye on every property, requirement, demand and request to expedite them and exceed our clients’ expectations.
                            Our system also benefits our Sellers and Buyers by making the process completely hassle-free, giving them time to focus on moving preparations while we take care of  the details of closing.
                        </p>
                        <p class="text-secondary">
                            As an added benefit, we conduct a cautionary check on each property that we handle. This assessment includes verification that the property is not under dispute and that it has no liens, pending bills, or tax problems. This assures our clients that they can carry out worry-free dealings with us.
                        </p>
                    </header>
                </div>
            </div>
            <br><br>
            <div class="row"  style="align-items: center;">
                <div class="col-12 col-md-6 p-5" style="display: flex; justify-content: center;">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/analyze-research-repeat.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h2 class="mb-4 mb-xl-6">
                        <strong class="h4 mb-2 d-block text-primary">Analyze, Research, Repeat</strong>	
                    </h2>
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">
                            Furthermore, we also conduct a detailed market analysis before marketing a property to estimate the actual status of a property compared to other properties in the same area and similar properties in different areas. This enables us to offer and receive fair prices based on all aspects of the properties. We promise our clients the honest truth and that is what we bring to the table. We will not present you with fairy tale prices— even for homes in the Bay Area. We will work to achieve the best deal for our clients.
                        </p>
                    </header>
                </div>
            </div>
            <br>
            <div class="row">
                <header class="header text-center text-md-center ">
                    <p style="margin: 0px; font-style: italic;" class="text-secondary p-2">As an added bonus, we offer our buyers a flat 1% credit back, as a token of appreciation, and because each completed deal means two parties can celebrate their freedom of movement! We are happy to take that bow on our earnings as a toast to your happiness and certainty.</p>
                </header>
            </div>
        </div>

        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute" style="right: -40px; bottom: 50px; width: 150px; z-index:1" data-icon="icon1">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute" style="left: -70px; top: 50px; width: 150px; " data-icon="icon1">
                    <img src="../../assets/images/icon7.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>   
        <br>
        <br>
        <br>
        <svg style="position: absolute; z-index:-1; bottom:0px;" xmlns="http://www.w3.org/2000/svg" width="1919.932" height="203.001" viewBox="0 0 1919.932 203.001">
            <path id="Subtraction_3" data-name="Subtraction 3" d="M1920.016,203.017H.083C2.576,200.81,65.454,148.554,439.7,110.6c26.881-2.726,54.912-5.1,83.312-7.053,26.972-1.856,55.174-3.395,83.821-4.574,26.965-1.11,55.279-1.942,84.155-2.47,26.825-.491,55.192-.74,84.313-.74,24.466,0,49.425.167,76.3.509,23.964.305,48.877.744,76.161,1.342,52.056,1.14,104.816,2.7,151.365,4.071h.018l4.285.127c48.431,1.43,98.51,2.908,147.089,3.992,26.125.583,49.825,1.011,72.455,1.308,25.375.333,48.782.5,71.56.5,75.116,0,140.823-1.828,200.875-5.589,19.382-1.214,38.282-2.567,56.176-4.024,17.19-1.4,33.917-2.93,49.717-4.551,29.611-3.038,57.077-6.5,81.635-10.3,42.227-6.533,76.92-14.191,103.116-22.763a223.663,223.663,0,0,0,27.791-10.943A95,95,0,0,0,1910,39.8c7.546-5.788,9.512-10.212,10.013-12.334V203.016Zm0-201.084h0L1920,.015h.015Z" transform="translate(-0.084 -0.015)" fill="#fff"/>
        </svg>
    </section>
    
    <section  class="serviceBlock position-relative py-10 pb-md-8 pt-lg-15 pt-xl-21 pb-lg-11" data-scroll-index="1">
        <!--Experience & Experts -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">We're Local</strong>	
                </h2>
            </div>
            <div class="row "  style="align-items: center; justify-content: space-between;">
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">We service a large section of California, including:</p>
                        <ul class="text-secondary">
                            <li>San Jose, Silicon Valley, and the South Bay
                                (Santa Clara County)</li>
                            <li>The East Bay
                                (Alameda, Contra Costa, and Solano Counties)</li>
                            <li>The Peninsula & San Francisco
                                (San Mateo and San Francisco Counties)</li>
                            <li>The North Bay
                                (Marin, Sonoma, Napa, and Solano Counties)</li>
                            <li>The Central Valley
                                (San Joaquin, Stanislaus, Sacramento, and Yolo Counties)</li>
                        </ul>
                    </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/location.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>
        <br><br>

        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute" style="left: -10px; top: 40%; width: 150px; transform: scale(-1,1);" data-icon="icon1">
                    <img src="../../assets/images/icon8.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>

    <!-- TESTIMONIALS -->
    <section style="background-color: #ecf2fd;"  class="serviceBlock position-relative py-10 pb-md-8 pt-lg-15 pt-xl-12 pb-lg-11" data-scroll-index="1">
        <svg style="position: absolute; z-index:-1; top:-100px"  xmlns="http://www.w3.org/2000/svg" width="1919.999" height="939.123" viewBox="0 0 1919.999 939.123">
            <path id="Union_1" data-name="Union 1" d="M-13649.573-7782.083c-183.7-12.945-353.613-2.128-515.81,8.69-208.383,13.9-404.041,27.795-599.873-8.69-348.27-64.891-345.721-225.292-345.721-225.292V-8611s433.679,99.012,1143.157,109.147h234.862c168.7-2.384,349.842-10.04,541.979-24.977v854.952S-13229.855-7752.517-13649.573-7782.083Z" transform="translate(15110.977 8611.001)" fill="#ecf2fd"/>
        </svg>

        <!-- CLIENT TESTIMONIALS -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h3 mb-2 d-block text-primary">Client Testimonials</strong>	
                </h2>
            </div>
            <div class="row flex-row-reverse"  style="align-items: center;">
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0 p-5" style="display: flex; justify-content: center;">
                        <img src="/../../assets/images/svg/testimonials-1.svg"  style="height: 270px;" class="img-fluid" alt="image description">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">
                            Freedom of Movement Realty is here to serve everyone who seeks the freedom to move. We work with all nationalities, all ethnicities, all people and backgrounds. We have successfully served people from all walks of life — working professionals, business owners, retirees, blue collar, white collar, teachers, and everyone else.
                        </p>
                    </header>
                </div>
            </div>
            <div class="row "  style="align-items: center;">
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0 p-5" style="display: flex; justify-content: center;">
                        <img src="/../../assets/images/svg/testimonials-2.svg" style="height: 250px;" class="img-fluid" alt="image description">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">
                            We have successfully sold all types of homes, from ordinary to unique both in high-demand and low-demand areas. Similarly, we have shown a talent for finding properties for even our most selective and unconventional clients. We specialize in fulfilling our clients’ wishes.
                        </p>
                    </header>
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <header class="header text-center text-md-center mb-6 mb-lg-9 p-2">
                    <p class="text-secondary">This high level of dedication to service has earned us the stellar reputation that we enjoy in today's real estate market. Check out how our clients describe their experience with Freedom of Movement Realty:</p>
                </header>
            </div>
            <div id="carouselId" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselId" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselId" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                            <app-testimonial-card [imgSrc]="testimonials[0].imgSrc" [name]="testimonials[0].name" [type]="testimonials[0].type"  [stars]="testimonials[0].stars" [review]="testimonials[0].review"></app-testimonial-card>
                    </div>
                    <div class="carousel-item">
                        <app-testimonial-card [imgSrc]="testimonials[1].imgSrc" [name]="testimonials[1].name" [type]="testimonials[1].type"  [stars]="testimonials[1].stars" [review]="testimonials[1].review"></app-testimonial-card>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselId" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselId" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <br><br>
          
        <svg style="position: absolute; z-index:-1; bottom: 0px" xmlns="http://www.w3.org/2000/svg" width="1922.611" height="221.707" viewBox="0 0 1922.611 221.707">
        <path id="Path_904" data-name="Path 904" d="M19023,16071.233v221.707H17100.389V16218s204.238-112.416,1021.2,18.734S19023,16071.233,19023,16071.233Z" transform="translate(-17100.389 -16071.233)" fill="#fff"/>
        </svg>
        
        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute"  style="width: 150px; z-index: -1; left:15%; bottom: 400px; transform: scale(-1,1);" data-icon="icon1">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon=""  style="width: 150px; z-index: -1; bottom: 70px; right: 14%">
                    <img src="../../assets/images/icon7.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon="icon5"  style="width: 150px; z-index: -1; bottom: 140px;">
                    <img src="../../assets/images/icon5.png" alt="icon" class="img-fluid">
                </span>
                <span  class="position-absolute icnImg" style="width: 150px; z-index: -1; bottom: 50px;" data-icon="icon6">
                    <img src="../../assets/images/icon6.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>

    <!-- contactBlock of the page -->
    <app-contact-form></app-contact-form>

    <br><br>
</main>