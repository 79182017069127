<div class="sticky-wrap-pageHeader">
  <header class="pageHeader animated fadeInDown" id="navbar">
    <div class="container position-relative">
      <div class="row align-items-center">
        <div class="col-6 col-sm-4 col-lg-3">
          <!-- logo of the page -->
          <div class="logo">
            <a [routerLink]="['home']">
              <img
                src="../../../assets/images/logo.png"
                alt="FOM Realty"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
        <div class="col-6 col-sm-8 col-lg-9 position-static">
          <!-- mainNavHolder of the page -->
          <div
            class="
              mainNavHolder
              d-flex
              flex-wrap
              align-items-center
              justify-content-end
            "
          >
            <div class="btnHolder d-sm-block order-lg-2 ml-n5 ml-sm-0 ml-lg-8">
              <a
                [routerLink]="['/contact-us']"
                class="btn btn-primary text-uppercase rounded-pill fwSemibold"
              >
                contact us <span class="fas fa-arrow-right ml-1 ml-sm-2"></span>
              </a>
            </div>
            <!-- navbar of the page -->
            <nav
              class="navbar navbar-expand-lg navbar-light p-0 position-static"
            >
              <button
                class="navbar-toggler NavToggler ml-2 ml-sm-3"
                type="button"
                data-toggle="collapse"
                data-target="#pageMainNavCollapse"
                aria-controls="pageMainNavCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span style="color: #4a83e5">
                <i class="fas fa-bars"></i></span>
              </button>
              <div
                class="collapse navbar-collapse mainNavCollapse text-capitalize"
                id="pageMainNavCollapse"
              >
                <!-- navbar-nav of the page -->
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      class="nav-link smooth "
                      [ngClass]="[router.url === '/home' ? 'active' : '']"
                      [routerLink]="['home']"
                      [routerLinkActive]="['home']"
                      >Home <span class="sr-only">(current)</span></a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link smooth"
                      [ngClass]="[router.url === '/buyers' ? 'active' : '']"
                      [routerLink]="['buyers']"
                      [routerLinkActive]="['buyers']"
                      >Buyers</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link smooth"
                      [ngClass]="[router.url === '/sellers' ? 'active' : '']"
                      [routerLink]="['sellers']"
                      [routerLinkActive]="['sellers']"
                      >Sellers</a
                    >
                  </li>
                  <!-- <li class="nav-item">
                    <a
                      class="nav-link smooth"
                      [ngClass]="[router.url === '/services' ? 'active' : '']"
                      [routerLink]="['services']"
                      >Services</a
                    >
                  </li> -->
                  <li class="nav-item">
                    <a
                      class="nav-link smooth"
                      [ngClass]="[router.url === '/expertise' ? 'active' : '']"
                      [routerLink]="['expertise']"
                      [routerLinkActive]="['expertise']"
                      >Expertise</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link smooth"
                      [ngClass]="[router.url === '/about' ? 'active' : '']"
                      [routerLink]="['about']"
                      [routerLinkActive]="['about']"
                      >About Us</a
                    >
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
