<div class="pageWrapper">
    <!-- pageHeader of the page -->
    <app-header></app-header>
    <!-- main of the page -->
    <router-outlet></router-outlet>
    <br>
    <!-- pageFooter of the page -->
    <app-footer></app-footer>
    <!-- Modal -->
    <div class="modal p-0 fade px-3" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <!-- modal-dialog of the page -->
        <div class="modal-dialog contactPopup mx-auto" role="document">
            <!-- modal-content of the page -->
            <div class="modal-content text-center">
                <!-- contactForm of the page -->
                <div class="contactForm modal-body py-10 px-4 pt-sm-13 pb-sm-14 px-sm-11">
                    <!-- contactForm of the page -->
                    <form id="contactForm" data-toggle="validator" novalidate="true">
                        <h6 class="h6 text-capitalize mb-1">Get Your Free Quote</h6>
                        <p>Supporting call-to-action goes here</p>
                            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div class="row">
                            <div class="col-12">
                                <input id="name" class="form-control" type="text" placeholder="Your Name" required="" data-error="NEW ERROR MESSAGE">
                            </div>
                            <div class="col-12">
                                <input id="email" class="form-control" type="emial" placeholder="Your Email" required="" data-error="NEW ERROR MESSAGE">
                            </div>
                            <div class="col-12">
                                <textarea id="message" class="form-control" placeholder="Your Message" required="" data-error="NEW ERROR MESSAGE"></textarea>
                            </div>
                            <div class="col-12">
                                <div id="msgSubmit" class="form-message"></div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary py-3 w-100 text-uppercase rounded-pill fwSemibold">sned your message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- back top of the page -->
    <span id="back-top" class="text-center rounded-circle fa fa-angle-up"></span>
    <!-- loader of the page -->
    <div id="loader" class="loader-holder" style="display: none;">
        <div class="block"><img src="../../assets/images/svg/puff.svg" width="100" alt="loader"></div>			
    </div>
</div>
	