<div *ngIf="!isLeft && isLeft != null" style="background-color: white; border-radius: 15px; position: relative;" class="p-3">
    <svg style="z-index:0; position: absolute;top:0px;right:-40px; width:50px; height:50px;" xmlns="http://www.w3.org/2000/svg" width="198.002" height="198" viewBox="0 0 198.002 198">
        <path id="Path_557" data-name="Path 557" d="M7955,18732h198l-198,198Z" transform="translate(-7955 -18732)" fill="#fff"/>
    </svg>
      
    <p class="text-secondary" style="margin: 0px;">{{message}}</p>
</div>

<div *ngIf="isLeft  && isLeft != null" style="background-color: white; border-radius: 15px; position: relative; z-index: 1;" class="p-3">
    <svg style="z-index:1; position: absolute;top:0px;left:-40px; width:50px; height:50px;  transform: scale(-1,1);" xmlns="http://www.w3.org/2000/svg" width="198.002" height="198" viewBox="0 0 198.002 198">
        <path id="Path_557" data-name="Path 557" d="M7955,18732h198l-198,198Z" transform="translate(-7955 -18732)" fill="#fff"/>
    </svg>
      
    <p class="text-secondary" style="margin: 0px;">{{message}}</p>
</div>

<div *ngIf="isLeft == null" style="background-color: white; border-radius: 15px; position: relative; z-index: 1;" class="p-3">
    <p class="text-secondary" style="margin: 0px;">{{message}}</p>
</div>