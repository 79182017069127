import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  selectedUser: string = null;
  testimonials: {
    name: string;
    type: string;
    stars: number;
    review: string;
    imgSrc: string;
  }[] = [
    {
      name: 'Client 1',
      type: 'Techy Buyer',
      review:
        "After searching in San Francisco for almost 6 months, I spoke to Siraj at Freedom of Movement Realty, and he helped us navigate and win a home in San Francisco's competitive real estate market. He took the time to understand what we were looking for and tailored his approach in helping us find the perfect home. His experience in legal issues and the Bay Area real estate market made him an excellent agent to work with. Best of all, he understood that buying a home",
      stars: 4,
      imgSrc:"techy-buyer.svg"
    },
    {
      name: 'Client 2',
      type: 'Retiree',
      review:
        'After retirement, I had enough funds to fulfill the plan I had worked all my life for, i.e. to buy the house of my dreams. I contacted many real estate agents, and though they found listings, I ran into issues with the houses I liked that made the deal fall apart. Problems with inspections, unknown pest issues or hidden liens and disputes. Freedom of Movement Realty was different. Their advance check and inspection policy helped me avoid those problems and made my dream home a reality. The houses I visited with them were ready to buy, and the attention from Mr X during the escrow period was commendable. Freedom of Movement Realty delivers on what it promises, i.e. freedom to move.',
      stars: 5,
      imgSrc: "retiree.svg"
    },
  ];

  buyersServices: { text: string; title: string }[] = [
    {
      text: 'When you are on the lookout for a property, most buyers only have the option is to wait for an open house. Freedom of Movement Realty eliminates  the hassle —a dedicated real estate agent will be assigned to you to simplify the search for your dream home.',
      title: 'Research',
    },
    {
      text: 'Our agents work alongside you through the entire process. Starting with inspecting your house, the agent will conduct a detailed analysis of the condition and marketability of your property. This includes comparative marketing analysis, inspections, usability, and assessments to determine the viability of selling your property. ',
      title: 'Analysis & Inspections',
    },
    {
      text: 'After viewing a property, we generate a report to give you a complete picture of the property and offer insights to help you better understand the seller’s disclosure report and how to evaluate all that the property has to offer.',
      title: 'Reporting',
    },
    {
      text: 'After you choose a property, we generate market and comparative analysis to give you valuable insights regarding the value of the property and help you determine a fair offer. We will work on your behalf to present an offer and  negotiate terms to ensure that your offer is accepted at a fair price.',
      title: 'Bargaining',
    },
    {
      text: 'From there we assist you through the escrow process making sure that all the demands and requirements are fulfilled before payment and transfer of ownership.',
      title: 'Escrow',
    },
    {
      text: 'Upon closing, we will celebrate our success with a 1% credit to you towards closing.  This is our housewarming gift to you — a wish for a beautiful new beginning in your new home. ',
      title: 'Finalization',
    },
  ];

  sellersServices: { text: string; title: string }[] = [
    {
      text: 'Selling a property is a lengthy, extensive and complicated process. The most crucial step is choosing the right real estate agent — an agent who can make the process simple, easy, and customized for you. Freedom of Movement Realty has a team of richly experienced and skilled real estate agents, each with expertise in particular types of properties, so with us you will always get an expert for every niche.',
      title: 'Research',
    },
    {
      text: 'Our agents work alongside you through the entire process. Starting with inspecting your house, the agent will conduct a detailed analysis of the condition and marketability of your property. This includes comparative marketing analysis, inspections, usability, and assessments to determine the viability of selling your property.',
      title: 'Inspections',
    },
    {
      text: 'We then prepare professional reports to explain the situation to you and make expert suggestions for any appropriate changes and repairs that will elevate your property to its highest potential value. To help you with this process, we provide referrals to excellent, highly-skilled service providers to do the work on your home while also making sure everything meets necessary requirements.',
      title: 'Reporting',
    },
    {
      text: 'Once your property is ready, we conduct further research on the real estate market trends and requirements to ensure that you get fair prices for your property. We prepare all the necessary disclosures to meet the compliance requirements of the State of California, the County and any local or city ordinances.',
      title: 'Valuation',
    },
    {
      text: 'The next step is marketing your property.  We use creative photography, cinematic videography, professional 3D designs, various channels including print, electronic, digital, BTL, TTL, ATL and direct targeting — all to get maximum exposure for your property.',
      title: 'Marketing',
    },
    {
      text: 'After securing a suitable offer, our real estate agents follow through on your behalf to close the deal with the buyer and guide you through every step of the escrow process until the deal is closed.',
      title: 'Finalization',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onClickClient(userType) {
    console.log(userType);
    if (userType === 'To Buyers' && this.selectedUser === 'Buyers') {
      this.selectedUser = null;
      return;
    }
    if (userType === 'To Sellers' && this.selectedUser === 'Sellers') {
      this.selectedUser = null;
      return;
    }

    if (userType === 'To Buyers') this.selectedUser = 'Buyers';
    if (userType === 'To Sellers') this.selectedUser = 'Sellers';
  }
}
