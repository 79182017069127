import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss']
})
export class ImageButtonComponent implements OnInit {
  @Input() imgSrc : string;
  @Input() text : string;
  @Input() isSelected : boolean;

  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickHandler(){
    this.onClick.emit(this.text);
  }

}
