<main>
    <!-- introBlock of the page -->
    <section class="introBlock position-relative d-flex" data-scroll-index="0"> 
        <div class="alignCenter w-100 d-flex align-items-center">
            <div class="container pt-25 pt-sm-30 pt-md-36 pt-xl-40 pb-10">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <!-- caption of the page -->
                        <div class="caption pr-lg-4 pr-xl-12 mb-6">
                            <!-- <a href="tel:0123456789" class="btn btn-sm btn-outline-primary text-uppercase mb-4 fwSemibold">Call us: <span>0123456789</span></a> -->
                            <h1 style="color:#3F3D56; font-size: 4rem; font-weight: bold;" class="text-capitalize mb-4 mb-lg-5">Freedom of Movement Realty</h1>
                            <p style="color:#3F3D56; font-size: 1.2rem; font-weight: lighter;">Finding you Houses that become your Homes</p>
                            <div class="btnHolder ">
                                <a  class="btn btn-primary text-uppercase rounded-pill fwSemibold" [routerLink]="['/contact-us']">
                                    contact us <span class="fas fa-arrow-right ml-1 ml-sm-2"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <span class="imgHolder mx-auto d-block pt-md-3">
                            <img src="../../assets/images/svg/home-illustration-main.svg" alt="image description" class="img-fluid">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bgHolder">
            <svg class="svgBg">
                <defs>
                    <clipPath id="pt02" clipPathUnits="objectBoundingBox">
                        <path d="M 0 0 L 1 0 L 1 0.057586 C 0.76770 0.091407 0.755729 0.45978 0.58281 0.66453 C 0.379166 0.942413 0.156770 0.544789 0 0.88665 L 0 0 Z"></path>
                    </clipPath>
                </defs>
                <rect width="100%" height="100%" style="clip-path: url(#pt02);" fill="#F5F8FE" ></rect>
            </svg>
            <!-- iconGroupImg of the page -->
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute icnImg" data-icon="icon1">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                
                <!-- <span class="position-absolute icnImg" data-icon="icon2">
                    <img src="../../assets/images/icon2.png" alt="icon" class="img-fluid">
                </span> -->
                <span class="position-absolute icnImg" data-icon="icon3">
                    <img src="../../assets/images/icon3.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon="icon4">
                    <img src="../../assets/images/icon4.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" data-icon="icon5">
                    <img src="../../assets/images/icon5.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute icnImg" style="width: 180px;" data-icon="icon6">
                    <img src="../../assets/images/icon6.png" alt="icon" class="img-fluid">
                </span>
            </div>
        </div>
    </section>
    <!-- brandingBlock of the page -->
    <!-- abtBlock of the page -->
    <section class="abtBlock py-10 pb-md-8 pt-lg-15 pt-xl-21 pb-lg-11" data-scroll-index="1">
        <div class="container">
            <div class="row flex-row-reverse">
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <h2 class="mb-4 mb-xl-6">
                            <strong class="h6 mb-2 d-block text-primary font-weight-normal">Our Mission</strong>	
                            <strong class="h3 d-block">Our family, here at Freedom of Movement, is committed to finding your perfect new home.</strong>
                        </h2>
                        <p class="text-secondary">Our premium real estate offerings are hand-selected by expert professionals ensuring that you receive only the best real estate selections.</p>
                        <p  class="text-secondary">Our aim is professional attention and seamless efficiency — that is why our website grants you full access to our premium selections with just a tap of a button.</p>
                        <p  class="text-secondary">Our family wants only the best for you. Your journey to finding your new home begins by connecting with our competent and friendly staff. Join us today to make your real estate dreams an easy reality- finding a home for your family.</p>
                        
                        </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/our-mission-illust.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <br>
    <section style="background-color: #ecf2fd;" class="serviceBlock position-relative py-10 pb-md-8 pt-lg-15 pt-xl-21 pb-lg-11" data-scroll-index="1">
        <svg style="position: absolute; z-index:-1; top:-100px"  xmlns="http://www.w3.org/2000/svg" width="1919.999" height="939.123" viewBox="0 0 1919.999 939.123">
            <path id="Union_1" data-name="Union 1" d="M-13649.573-7782.083c-183.7-12.945-353.613-2.128-515.81,8.69-208.383,13.9-404.041,27.795-599.873-8.69-348.27-64.891-345.721-225.292-345.721-225.292V-8611s433.679,99.012,1143.157,109.147h234.862c168.7-2.384,349.842-10.04,541.979-24.977v854.952S-13229.855-7752.517-13649.573-7782.083Z" transform="translate(15110.977 8611.001)" fill="#ecf2fd"/>
            </svg>
            
        <!-- WHO ARE WE SECTION -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">Who we are</strong>	
                </h2>
            </div>
            <div class="row flex-row-reverse"  style="align-items: center;">
                <div class="col-12 col-md-6">
                    <div class="imgHolder  mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0" style="width: 75%;">
                        <img src="/../../assets/images/svg/who-are-we.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">The population is expanding, and the world is shrinking. Many people struggle to find a suitable property with all the amenities they need for their family  that is within their budget. 
                        </p>
                        <p class="text-secondary">
                            With Freedom of Movement Realty by your side, we remove these challenges and have a mission to help everyone achieve the freedom to move wherever they want.  We identify properties according to the needs and amenities that clients dream of and in a budget that is lower than their expectations.
                        </p>
                        </header>
                 </div>
                
            </div>
        </div>
        <br><br>
        <!-- HOW WE DO IT -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">How do we do it?</strong>	
                </h2>
            </div>
            <div class="row flex-row-reverse"  style="align-items: center;">
                <div class="col-12 col-md-6">
                    <header class="header text-center text-md-left mb-6 mb-lg-9">
                        <p class="text-secondary">Freedom of Movement Realty has years of experience in the real estate market. The biggest asset that we have created for ourselves is our reputation that tells the story of our professionalism, efficiency and a dedicated network of real estate agents who will hunt down the right property for every buyer and a buyer for every property.</p>
                        <p class="text-secondary">All of our agents are experienced and well-trained in their profession. Each agent is dedicated to providing the best service to every client, thoroughly understanding their needs and wishes and fulfilling them as quickly and efficiently  as possible.</p>
                    </header>
                </div>
                <div class="col-12 col-md-6">
                    <div class="imgHolder mx-auto pt-5 pt-md-2 ml-md-n12 ml-lg-n4 ml-xl-0">
                        <img src="/../../assets/images/svg/how-we-do-it-illust.svg" class="img-fluid" alt="image description">
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <!-- WHY US -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6 mb-sm-15">
                    <strong class="h4 mb-2 d-block text-primary">Why us?</strong>	
                </h2>
            </div>
            <div class="row"  style="align-items: center;">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 justify-content-center ">
                        <div class="imgHolder"  style="height: 350px; width: 350px; display: flex; justify-content: center; align-items: center; margin: auto;">
                            <img src="/../../assets/images/svg/future-proof-illust.svg" class="img-fluid" alt="image description">
                        </div>
                        <div class="header text-center text-md-center mb-6 mb-lg-9  p-2">
                            <h2 class="mb-4 mb-xl-6">
                                <strong class="h5 mb-2 d-block text-primary">Future proof</strong>	
                            </h2>
                            <p class="text-secondary">For starters, our company is technologically-infused with the most modern technological tools available for the real estate market in the form of our CRM. Our CRM helps us maintain and monitor records for each of our clients with details about their requirements that are immediately available to us.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 justify-content-center">
                        <div class="imgHolder"  style="height: 350px; width: 350px; display: flex; justify-content: center; align-items: center; margin: auto;">
                            <img src="/../../assets/images/svg/efficient-effective-illust.svg" class="img-fluid" alt="image description">
                        </div>
                        <header class="header text-center text-md-center mb-6 mb-lg-9 p-2">
                            <h2 class="mb-4 mb-xl-6">
                                <strong class="h5 mb-2 d-block text-primary">Efficient and Effective</strong>	
                            </h2>
                            <p class="text-secondary">We are known for saving time by streamlining our procedures to address the most unique demands for each situation. In addition, we also run thorough checks before dealing with any property so that we minimize any surprises and provide ample opportunity to make informed decisions.</p>
                        </header>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 justify-content-center">
                        <div class="imgHolder" style="height: 350px; width: 350px; display: flex; justify-content: center; align-items: center; margin: auto;">
                            <img src="/../../assets/images/svg/well-trained-agents-illust.svg" class="img-fluid" alt="image description">
                        </div>
                        <header class="header text-center text-md-center mb-6 mb-lg-9 p-2">
                            <h2 class="mb-4 mb-xl-6">
                                <strong class="h5 mb-2 d-block text-primary">Well Trained Agents</strong>	
                            </h2>
                            <p class="text-secondary">Our real estate agents accompany you on each step of the way and assist you in every way possible to make the process easy and hassle-free. We have trained our agents to be successful negotiators who understand the intricacies of the real estate market.</p>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <br>
    <br>
    
    <!-- OUR SERVICES -->
    <section  class="serviceBlock position-relative pb-md-8 pb-lg-11" data-scroll-index="1">
        <!--Experience & Experts -->
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="mb-4 mb-xl-6">
                    <strong class="h4 mb-2 d-block text-primary">Our Services</strong>	
                </h2>
                <header class="header text-center text-md-center mb-6 mb-lg-9">
                    <p class="text-secondary">Freedom of Movement Realty provides the best real estate services to both buyers and sellers. 
                        Our process is tailored to meet the unique needs of each client.</p>
                        <p class="text-secondary"> <strong>Click Below to see what services we provide for you!</strong></p>
                </header>
            </div>
            <div class="row "  style="align-items: center; justify-content: space-between;">
                <div class="col-12 col-md-6 p-2">
                    <app-image-button (onClick)="onClickClient($event)" [isSelected]="selectedUser === 'Buyers'" [imgSrc]="'/../../assets/images/svg/buyers.svg'" [text]="'To Buyers'"></app-image-button>
                </div>
                <div class="col-12 col-md-6 p-2">
                    <app-image-button (onClick)="onClickClient($event)" [isSelected]="selectedUser === 'Sellers'"  [imgSrc]="'/../../assets/images/svg/sellers.svg'" [text]="'To Sellers'"></app-image-button>
                </div>
            </div>
            <br>

            <div [hidden]="selectedUser !== 'Buyers'" class="p-7 frosted-glass" style="background-color: #ecf2fd60;  z-index: -200; border-radius: 15px;">
                <div class="row ">
                    <h2 class="mb-4 mb-xl-6">
                        <strong class="h4 mb-2 d-block text-primary">To Buyers</strong>	
                    </h2>
                </div>
                <div *ngFor="let step of buyersServices; let i = index" [ngClass]="['row', 'd-none', 'd-lg-flex', i%2!=0 ? 'flex-row-reverse' : '']">
                    <div class="col-xl-5 col-lg-5 col-sm-12 mb-5">
                        <app-message-bubble [isLeft]="i%2!=0" [message]="step.text"></app-message-bubble>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-1" style="justify-content: center; display: flex">
                        <div style="width: 2px; height: 100%; position: absolute; background-color: black; align-self: center;"></div>
                        <div class="text-md-center" style="background-color: #6C63FF; border-radius: 100px; color: white; width: 50px; height: 50px; z-index:2"><p class="text-light" style="line-height: 50px; text-align: center;">{{i+1}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mb-5" style="display: flex; justify-content: center;">
                        <div style="margin:auto"><p style="text-align: center;" class="text-primary h6">{{step.title}}</p></div>
                    </div>
                </div>

                <div *ngFor="let step of buyersServices; let i = index" [ngClass]="['row', 'd-md-flex', 'd-xl-none', 'd-lg-none', 'd-sm-flex', 'd-xs-flex', i%2!=0 ? 'flex-row-reverse' : '']">
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-1" style="justify-content: center; display: flex">
                        <div style="width: 2px; height: 100%; position: absolute; background-color: black; align-self: center;"></div>
                        <div class="text-md-center" style="background-color: #6C63FF; border-radius: 100px; color: white; width: 50px; height: 50px; z-index:2"><p class="text-light" style="line-height: 50px; text-align: center;">{{i+1}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mb-2" style="display: flex; justify-content: center;">
                        <div style="margin:auto"><p style="text-align: center;" class="text-primary h6">{{step.title}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-sm-12 mb-10">
                        <app-message-bubble [isLeft]="null" [message]="step.text"></app-message-bubble>
                    </div>
                </div>
            </div>

            <div [hidden]="selectedUser !== 'Sellers'" class="p-7 frosted-glass" style="background-color: #ecf2fd60; border-radius: 15px;">
                <div class="row ">
                    <h2 class="mb-4 mb-xl-6">
                        <strong class="h4 mb-2 d-block text-primary">To Sellers</strong>	
                    </h2>
                </div>
                <div *ngFor="let step of sellersServices; let i = index" [ngClass]="['row', 'd-none', 'd-lg-flex', i%2!=0 ? 'flex-row-reverse' : '']">
                    <div class="col-xl-5 col-lg-5 col-sm-12 mb-5">
                        <app-message-bubble [isLeft]="i%2!=0" [message]="step.text"></app-message-bubble>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-1" style="justify-content: center; display: flex">
                        <div style="width: 2px; height: 100%; position: absolute; background-color: black; align-self: center;"></div>
                        <div class="text-md-center" style="background-color: #6C63FF; border-radius: 100px; color: white; width: 50px; height: 50px; z-index:2"><p class="text-light" style="line-height: 50px; text-align: center;">{{i+1}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mb-5" style="display: flex; justify-content: center;">
                        <div style="margin:auto"><p style="text-align: center;" class="text-primary h6">{{step.title}}</p></div>
                    </div>
                </div>

                <div *ngFor="let step of sellersServices; let i = index" [ngClass]="['row', 'd-md-flex', 'd-xl-none', 'd-lg-none', 'd-sm-flex', 'd-xs-flex', i%2!=0 ? 'flex-row-reverse' : '']">
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-1" style="justify-content: center; display: flex">
                        <div style="width: 2px; height: 100%; position: absolute; background-color: black; align-self: center;"></div>
                        <div class="text-md-center" style="background-color: #6C63FF; border-radius: 100px; color: white; width: 50px; height: 50px; z-index:2"><p class="text-light" style="line-height: 50px; text-align: center;">{{i+1}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mb-2" style="display: flex; justify-content: center;">
                        <div style="margin:auto"><p style="text-align: center;" class="text-primary h6">{{step.title}}</p></div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-sm-12 mb-10">
                        <app-message-bubble [isLeft]="null" [message]="step.text"></app-message-bubble>
                    </div>
                </div>
            </div>
        </div>

        <div class="bgHolder">
            <div class="iconGroupImg d-none d-sm-block">
                <span class="position-absolute " data-icon="" style="width: 100px; left: 50px; transform: scale(-1,1); top:100px">
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute " data-icon="" style="width: 30px; left: 125px; bottom: 100px" >
                    <img src="../../assets/images/icon7.png" alt="icon" class="img-fluid">
                </span>
                <span class="position-absolute " data-icon="" style="width: 90px; left: 17%; z-index:-1; top: 240px" >
                    <img src="../../assets/images/icon8.png" alt="icon" class="img-fluid">
                </span>
                
                <span *ngIf="selectedUser != null" class="position-absolute " data-icon="" style="width: 50px; right: 17%; top: 600px; z-index:-1;" >
                    <img src="../../assets/images/icon3.png" alt="icon" class="img-fluid">
                </span>
                <span *ngIf="selectedUser != null" class="position-absolute " data-icon="" style="width: 50px; left: 18%; top: 550px; z-index:-1;" >
                    <img src="../../assets/images/icon5.png" alt="icon" class="img-fluid">
                </span>


                <span *ngIf="selectedUser != null" class="position-absolute " data-icon="" style="width: 50px; right: 17%; top: 40%; z-index:-1;" >
                    <img src="../../assets/images/icon8.png" alt="icon" class="img-fluid">
                </span>
                <span *ngIf="selectedUser != null" class="position-absolute " data-icon="" style="width: 140px; left: 13%; top: 30%; z-index:-1;" >
                    <img src="../../assets/images/icon1.png" alt="icon" class="img-fluid">
                </span>
                <span *ngIf="selectedUser != null" class="position-absolute " style="width: 220px; left: 13%; top: 60%; z-index:-1;">
                    <img src="../../assets/images/icon13.png" alt="icon" class="img-fluid">
                </span>
                <span *ngIf="selectedUser != null" class="position-absolute" style="width: 190px; right: -25px; bottom: 10%; z-index:-1;">
                    <img src="../../assets/images/icon19.png" alt="icon" class="img-fluid">
                </span>
                <span *ngIf="selectedUser != null" class="position-absolute" style="width: 40px; left: 30%; bottom: 40px; z-index:-1;">
                    <img src="../../assets/images/icon8.png" alt="icon" class="img-fluid">
                </span> 
                <span *ngIf="selectedUser != null" class="position-absolute" style="width: 50px; left: 17%; bottom: 20%; z-index:-1;">
                    <img src="../../assets/images/icon7.png" alt="icon" class="img-fluid">
                </span> 
            </div>
        </div>
    </section>
    <br>


    <br><br>
    <!-- contactBlock of the page -->
    <app-contact-form></app-contact-form>

    <br><br>
</main>