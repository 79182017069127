import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss']
})
export class ExpertiseComponent implements OnInit {
  selectedUser: string = null;

  constructor() {}

  ngOnInit(): void {}

  onClickClient(userType) {
    console.log(userType);
    if (userType === 'To Buyers' && this.selectedUser === 'Buyers') {
      this.selectedUser = null;
      return;
    }
    if (userType === 'To Sellers' && this.selectedUser === 'Sellers') {
      this.selectedUser = null;
      return;
    }

    if (userType === 'To Buyers') this.selectedUser = 'Buyers';
    if (userType === 'To Sellers') this.selectedUser = 'Sellers';

    window.scrollTo(0,window.screen.height * 120/100);
  }

}
