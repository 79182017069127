<div class="col-12 text-md-center text-sm-center justify-content-center p-10" style="border-radius:25px;background-color: rgba(255, 255, 255, .35); backdrop-filter: blur(5px);">
    <div class="circle-avatar" style="overflow: hidden; background-color: white; border-radius: 100px; width: 100px; height:100px; margin: auto; border: 2px solid #3062b8">
    <img [src]="'../../../assets/images/svg/'+imgSrc" alt="" srcset="" class="img-fluid"></div>
    <br>
    <div class="header text-sm-center" style="margin: auto;">
        <h2 class="text-center" style="margin:auto; text-align: center;">
            <strong style="margin: 0px; padding: 0px;" class="h3 d-block text-primary">{{name}}</strong>	
            <p style="margin: 0px; padding: 0px;" class="h6 text-secondary font-weight-normal" >{{type}}</p>
        </h2>

        <br>
        <div *ngFor="let i of stars" >
            <i class="fas fa-star" style="color:#6C63FF"></i>
        </div>

        <br>

        <p class="text-secondary text-md-center text-center" style="width: 80%; margin: auto;">{{review}}</p>
    </div>

    <div class="bgHolder">
        <div class="iconGroupImg d-none d-sm-block">
            <span class="position-absolute"  style="width: 100px; z-index: -1; left: 40px; top: 50%;" data-icon="">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" class="svg-inline--fa fa-quote-left fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ecf2fd" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path></svg>
            </span>
            <span class="position-absolute"  style="width: 100px; z-index: -1; right: 40px; bottom: 10px;" data-icon="">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-right" class="svg-inline--fa fa-quote-right fa-w-16" role="img" viewBox="0 0 512 512"><path fill="#ecf2fd" d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"/></svg>
            </span>
        </div>
    </div>
</div>